import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const HiddenElement = styled.div`
  opacity: 0;
  transform: translateX(${props => props.fromLeft ? "-" : ""}100%);
  transition: transform 1s ease-out, opacity 1s ease-out;

  &.visible {
    opacity: 1;
    transform: translateX(0);
  }
`;

const FlyingComponent = ({ children, fromLeft, delay = 0 }) => {
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            if (ref.current) {
              ref.current.classList.add('visible');
            }
          }, delay); // Задержка перед анимацией
        }
      },
      { threshold: 0.01 }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [delay]);

  return (
    <HiddenElement fromLeft={fromLeft} ref={ref}>
      {children}
    </HiddenElement>
  );
};

export default FlyingComponent;
