import React from "react";
import styled from "styled-components";
import GreenButton from "./GreenButton";
import Text from "./Text";
import { colors } from "../lib/styles";
import PhoneNumberInput from "./PhoneInput";


const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    
    @media(max-width: 800px) {
        grid-template-columns: 1fr;
    }
`

const Input = styled.input`
    padding: 10px;
    border-radius: 10px;
    border: none;
    width: 100%;
`

const TextArea = styled.textarea`
    padding: 10px;
    border-radius: 10px;
    border: none;
    width: 100%;
`

const FormContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-end;
`

const Icon = styled.img`
    width: 100%;
`


const ResponseForm = () => {
    return <Container>
        <FormContainer>
            <Text type="title" color={colors.main}>У вас есть вопросы?</Text>
            <Text type="title">Заполните данные и мы вам все расскажем!</Text>
            <PhoneNumberInput />
            <TextArea placeholder="Напишите ваше сообщение"/>
            <div style={{display: "flex", justifyContent: "end"}}>
                <GreenButton>
                    Отправить
                </GreenButton>
            </div>
        </FormContainer>
        <Icon src="/icons/individual_solutions.svg"/>
    </Container>
}

export default ResponseForm;