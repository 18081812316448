import React from "react";
import styled from "styled-components";
import Text from "./Text";
import { colors } from "../lib/styles";
import { radius } from "../lib/styles";
import { padding } from "../lib/styles";

const GrayRectangle = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    height: 200px;
    width: 100%;
    border-radius: 8px;
    background: #D9D9D933;
`

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    line-height: 40px;
    position: relative;
`

const RectanglesContainer = styled.div`
    display: flex;
    gap: 30px;
    width: 100%;

    @media(max-width: 800px) {
        flex-direction: column;
    }
`

const Benefit = styled.div`
    border-radius: ${radius.full};
    border: solid 2px ${colors.main};
    color: white;
    padding: ${padding.small};
    white-space: nowrap;
    font-size: 15px; 
`

const BenefitsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    width: 70%;
`

const benefits = [
    "Удобный личный кабинет",
    "Статистика и аналитика",
    "Защита от утечки информации",
    "Поддержка 24/7"
]

const Tarifs = () => {
    return <Container>
        <Text color={colors.main} type="title">Тарифы</Text>
        <RectanglesContainer>
            <GrayRectangle>
                <Text type="title">ТРЕЙДЕРЫ</Text>
            </GrayRectangle>
            <GrayRectangle>
                <Text type="title">МЕРЧАНТЫ</Text>
            </GrayRectangle>
        </RectanglesContainer>
        <BenefitsContainer>
            {benefits.map(benefit => <Benefit>{benefit}</Benefit>)}
        </BenefitsContainer>
    </Container>
}

export default Tarifs;