import React from "react";
import styled from "styled-components";
import Text from "./Text";
import Flex from "./Flex";


const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const Line = styled.div`
  width: 100%; /* Линия на всю ширину родительского контейнера */
  height: 1px; /* Толщина линии */
  background-color: #ccc; /* Цвет линии */
  margin: 20px 0; /* Отступы сверху и снизу */
`;

const Icon = styled.img`
    width: 20px;
`;

const ContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media(max-width: 800px) {
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }
`

const Contact = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
`

const BanksGroup = styled.img`
    @media(max-width: 800px) {
        width: 100%;
    }
`


const Footer = () => {
    return <Container>
        <Line />
        <ContentContainer>
            <img width="150px" src='/icons/logo_white.svg'/>

            <Flex column gap="5px" >
                <Contact><Icon src="/icons/tg_icon.svg"/>@many-pay</Contact>
                <Contact><Icon src="/icons/mail_icon.svg"/>many-pay@many-pay.com</Contact>
            </Flex>

            <BanksGroup width="100%" src='/icons/banks_group.svg'/>

            <Flex column style={{whiteSpace: "nowrap"}}>
                <Text type="subtext">
                    ©2024.Все права защищены.
                </Text>
                <Text type="subtext">
                    Политика конфиденциальности
                </Text>
            </Flex>
            
        </ContentContainer>
        
    </Container>
}

export default Footer;