import React from 'react';
import { useAuth } from '@shared/AuthContext';
import { useState } from 'react';
import styled from 'styled-components';
import Loading from '@shared/ui/Loading';
import LogoutIcon from '@shared/ui/icons/LogoutIcon';


const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
`

export const UserButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin: 20px 32px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const LogoutButton = () => {
    const {logout} = useAuth();
    const [logoutLoading, setLogoutLoading] = useState(false);

    return <Block>
        {logoutLoading ?
        <Loading size="xsmall" />
            :
        <UserButton onClick={handleLogout}>
            <LogoutIcon />
        </UserButton>
        }
    </Block>

    async function handleLogout() {
        setLogoutLoading(true);
        try {
            await logout();
        } catch(e) {
            console.log(e);
        } finally {
            setLogoutLoading(false)
        }
    }
}

export default LogoutButton;