import { commonPreset } from "./common";

export default {
    default: {
        ...commonPreset,
        paddingTop: "6px",
        paddingBottom: "6px"
    },
    large: {
        ...commonPreset,
        paddingTop: "8px",
        paddingBottom: "8px"
    },
}