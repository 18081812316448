export const sizes = {
    title: "2.5rem",
    pretitle: "2rem",
    subtitle: "1.5rem",
    small: "12px",
    default: "14px",
    subheader: "18px",
    medium: "24px",
    large: "32px",
    xlarge: "40px",
    card: "1rem",
    subtext: "0.8rem"
}

export const weights = {
    default: "400",
    semibold: "500",
    bold: "600"
}

export const spacing = {
    default: "0.5px"
}

export const aligns = {
    basic: "justify",
    card: "center",
    subtext: "justify"
}

export const borderRadiuses = {
    default: "15px"
}