import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div `
    display: flex;
    width: ${props => props.width || 'auto'};
    height: ${props => props.height || 'unset'};
    justify-content: ${props => props.customJust || 'space-between'};
    flex: ${props => props.flex || 'none'};
    min-width: min-content;
`

const HBox = ({children, customJust, gap, alignItems, backGround, borderRadius, width, flex, height, borderBottom, padding }) => {
    return <StyledDiv 
    customJust={customJust}
    style={{gap: gap, alignItems: alignItems, background: backGround, borderRadius, borderBottom: borderBottom, padding: padding}}
    width={width}
    flex={flex}
    height={height}>
        {children}
    </StyledDiv>
};

export default HBox;
