import axios from "axios";
import { api } from "./ApiProvider";


async function makeRequest(path, {body, method, headers} = {}) {
    return (await api({
        url: process.env.REACT_APP_API_HOST + path,
        method: method || "POST",
        headers: headers || {
            "Content-Type": "application/json"
        },
        data: body,
        withCredentials: true
    })).data;
}

export default makeRequest;