import { Button } from "@mui/material";
import React from "react";
import CloseIcon from "../icons/CloseIcon";


const CloseButton = ({onClick}) => {
  return (
    <Button 
        onClick={onClick}
        sx={{
            color: "gray", // Изначально серый цвет иконки
            minWidth: 0,
            "&:hover": {
            backgroundColor: "rgb(248,248,248)", // Белый фон при наведении
            color: "black", // Черная иконка при наведении
            },
        }}>
      <CloseIcon />
    </Button>
  );
};

export default CloseButton;
