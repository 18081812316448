// src/routes/merchantRoutes.js
import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import Loading from '@shared/ui/Loading';

const MerchantAssets = lazy(() => import('@merchant/Assets'));
const MerchantDeposit = lazy(() => import('@merchant/Deposit'));
const MerchantWithdrawl = lazy(() => import('@merchant/Withdrawl'));
const MerchantShops = lazy(() => import('@merchant/Shops'));
const MerchantOrders = lazy(() => import('@src/widgets/orders'));
const MerchantArchive = lazy(() => import('@merchant/Archive'));
const MerchantStatistics = lazy(() => import('@merchant/Statistics'));
const MerchantSettings = lazy(() => import('@merchant/Settings'));
const MerchantTransfers = lazy(() => import('@merchant/Internal_transfers'));
const MerchantHelp = lazy(() => import('@merchant/Help'));

const merchantRoutes = [
    { path: '/assets', element: <MerchantAssets /> },
    { path: '/deposit/shop/:shopName', element: <MerchantDeposit /> },
    { path: '/orders', element: <MerchantOrders /> },
    { path: '/shops', element: <MerchantShops /> },
    { path: '/statistics', element: <MerchantStatistics /> },
    { path: '/archive', element: <MerchantArchive /> },
    { path: '/settings', element: <MerchantSettings /> },
    { path: '/assets/internal_transfers', element: <MerchantTransfers /> },
    { path: '/assets/withdrawl', element: <MerchantWithdrawl /> },
    { path: '/help', element: <MerchantHelp /> },
];

export default merchantRoutes;
