import styled from "styled-components";
import GreenButton from "./GreenButton";
import { useNavigate } from "react-router-dom";


const Container = styled.div`
    display: flex;
    max-width: 1300px;
    width: 100%;
    padding: 20px 0 20px 0;
    justify-content: space-between;
    align-items: center;
`

const Icon = styled.img`
    width: 150px;
`;

const Logo = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--accent);
    margin: 0;
    text-align: center;
`;

const Elements = styled.div`
    display: flex;
    font-size: 13px;
    gap: 20px;

    @media(max-width: 750px) {
        display: none;
    } 
`

const Element = styled.div`
    cursor: pointer;
`


const Header = () => {
    const navigate = useNavigate();

    return <Container>
        <Logo>
            <Icon src='/icons/logo_white.svg'/>
        </Logo>
        <Elements>
            <Element>Преимущества</Element>
            <Element>Тарифы</Element>
            <Element>White label</Element>
            <Element>Контакты</Element>
        </Elements>
        
        <GreenButton onClick={() => {navigate("/login")}}>
            Личный кабинет
        </GreenButton>
    </Container>
}

export default Header;