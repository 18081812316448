import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div `
    display: flex;
    flex-direction: column;
    gap: ${props => props.gap || 'none'};
    min-width: min-content;
    transition: width 0.5s, min-width: 0.5s;
    height: ${props => props.height || 'auto'}
    user-select: ${props => props.userSelect || "auto"}
`

const VBox = ({children, userSelect, flex, boxShadow, padding, gap, width, backGround, customJust, borderRadius, borderBottom, fontSize, ...rest}) => {
    return <StyledDiv style={{flex: flex, userSelect, boxShadow: boxShadow, padding: padding, fontSize, borderRadius: '12px', gap: gap, width: width, background: backGround, justifyContent: customJust, borderBottom: borderBottom}} {...rest}>{children}</StyledDiv>
};

export default VBox;