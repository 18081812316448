import React from "react";
import styled from "styled-components";
import Header from "./elements/Header";
import Introduction from "./elements/Introduction";
import SecondBlock from "./elements/SecondBlock";
import Advantages from "./elements/Advantages";
import IndividualSolutions from "./elements/IndividualSolutions";
import { fonts } from "./lib/styles";
import Tarifs from "./elements/Tarifs";
import Footer from "./elements/Footer";
import ResponseForm from "./elements/ResponseForm";


const BackgroundImage = styled.div`
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: start;
    color: white;
    font-family: Manrope;
    font-size: ${fonts.basic};
    overflow-x: hidden;
    user-select: none;
    padding: 1rem;
    z-index: ${props => props.zIndex};
    background-image: url(${props => `${props.src}`}); /* Замените на вашу картинку */
    background-blend-mode: multiply; /* Наложение фона и цвета */
    background-size: cover; /* Масштабирование картинки по размеру контейнера */
    background-position: center; /* Центрирование картинки */
`

const BackgroundGradient = styled.div`
    background: linear-gradient(to bottom, black, #00103C);
    min-height: 100vh;
    z-index: -100;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
    gap: 50px;
`


const LandingPage = () => {
    return <BackgroundGradient>
        <BackgroundImage zIndex="-5" src="/icons/background_gradient.svg">
            <Container>
                <Header />
                <Introduction />
                <SecondBlock />
                <Advantages />
                <IndividualSolutions />
                <ResponseForm />
                <Footer />
            </Container>    
        </BackgroundImage>
    </BackgroundGradient>
}

export default LandingPage;