import React, {lazy} from 'react';

const Assets = lazy(() => import('@trader/Assets'))
const Deposit = lazy(() => import('@trader/Deposit'))
const Withdrawl = lazy(() => import('@trader/Withdrawl'))
const InternalTransfers = lazy(() => import('@trader/Internal_transfers'))
const Hubs = lazy(() => import('@trader/Hubs'))
const Orders = lazy(() => import('@src/widgets/orders'))
const Requisites = lazy(() => import('@trader/Requisites'))
const Devices = lazy(() => import('@trader/Devices'))
const Statistics = lazy(() => import('@trader/Statistics'))
const Settings = lazy(() => import('@trader/Settings'))
const Help = lazy(() => import('@trader/Help'))
const Payouts = lazy(() => import('@trader/Payouts'))


const traderRoutes = [
  { path: '/assets', element: <Assets /> },
  { path: '/deposit', element: <Deposit /> },
  { path: '/withdrawl', element: <Withdrawl /> },
  { path: '/internal_transfers', element: <InternalTransfers /> },
  { path: '/payouts', element: <Payouts /> },
  { path: '/hubs', element: <Hubs /> },
  { path: '/devices', element: <Devices /> },
  { path: '/hubs/:hub_id', element: <Requisites /> },
  { path: '/orders', element: <Orders /> },
  { path: '/statistics', element: <Statistics /> },
  { path: '/settings', element: <Settings /> },
  { path: '/help', element: <Help /> },
];

export default traderRoutes;

