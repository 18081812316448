import { Checkbox as AntdCheckbox } from "antd";
import Text from "./Text";
import styled from "styled-components";


const StyledCheckbox = styled(AntdCheckbox)`
    .ant-checkbox-inner {
        border-color: black;
        transition: background-color 0.3s, border-color 0.3s;
    }

    /* Цвет при наведении на чекбокс */
    .ant-checkbox-wrapper:hover .ant-checkbox-inner {
        border-color: black;
    }

    /* Цвет при выбранном состоянии */
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: black;
        border-color: black;
    }

    /* Цвет галочки внутри */
    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: white;
    }
`;


const Checkbox = () => {
    return <StyledCheckbox><Text>Запомнить меня</Text></StyledCheckbox>
}

export default Checkbox;