import styled from "styled-components";
import Text from "./Text";
import { colors } from "../lib/styles";
import FlyingComponent from "./FlyingComponent";


const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    line-height: 40px;
    position: relative;
`

const Background = styled.div`
    background-image: url("/icons/mark2.svg"); /* Замените на вашу картинку */
    background-blend-mode: multiply; /* Наложение фона и цвета */
    background-size: cover; /* Масштабирование картинки по размеру контейнера */
    background-position: center; /* Центрирование картинки */
    position: absolute;
    top: 0;
    z-index: -1;
`

const GlassCard = styled.div`
  background: rgba(255, 255, 255, 0.1); /* Немного прозрачный белый цвет */
//   backdrop-filter: blur(10px); /* Эффект размытости, создающий стеклянный вид */
  border-radius: 15px; /* Закругленные углы */
  flex-direction: column;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Тень для глубины */
  padding: 20px; /* Внутренние отступы для контента */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Полупрозрачная рамка */
  position: relative; /* Для позиционирования бликов */
  text-align: center;
  gap: 20px;
  height: 100%;
`;

const Icon = styled.img`
    max-width: 100px;
    color: red;
`

const benefits = [
    { src: "advantage_1", description: "Подходит для проектов без юридической регистрации" },
    { src: "advantage_2", description: "Гибкие условия сотрудничества" },
    { src: "advantage_3", description: "Быстрая скорость обработки большого потока платежей" },
    { src: "advantage_4", description: "Новые возможности для расширения географии деятельности и увеличения доходов вашей компании" },
    { src: "advantage_5", description: "Надежная защита от санкций и штрафов за прием платежей из России и СНГ" },
    { src: "advantage_6", description: "Брендированная платежная страница под ваш проект" }
];

const AdvantagesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    @media (max-width: 800px) {
        grid-template-columns: 1fr 1fr; /* Две колонки для ширины экрана 800px и меньше */
    }

    @media (max-width: 500px) {
        grid-template-columns: 1fr; /* Одна колонка для ширины экрана 500px и меньше */
    }
`


const Advantages = () => {
    return <Container>
        <Background />
        <Text type="title" color={colors.main}>Преимущества</Text>
        <AdvantagesContainer>
            {benefits.map((benefit, index) => <>
                <FlyingComponent delay={(index * 500)}>
                    <GlassCard>
                        <div style={{color: "white"}}>
                        <Icon src={`/icons/${benefit.src}.svg`}/>
                        </div>
                        
                        <Text lineHeight="20px">{benefit.description}</Text>
                    </GlassCard>
                </FlyingComponent>
            </>)}
        </AdvantagesContainer>
    </Container>
}

export default Advantages;