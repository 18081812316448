import React from "react";
import styled from "styled-components";
import Text from "./Text";
import { colors } from "../lib/styles";
import GreenButton from "./GreenButton";


const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    line-height: 40px;
`

const MainContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media(max-width: 800px) {
        grid-template-columns: 1fr;
    }
`

const Icon = styled.img`
    width: 100%;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media(min-width: 800px) {
        align-items: start;
    }
`


const IndividualSolutions = () => {
    return <Container>
        <Text color={colors.main} type="title">Индивидуальные решения</Text>
        <MainContainer>
            <div style={{display: "flex", flexDirection: "column", lineHeight: "30px", gap: "20px", alignItems: "center"    }}>
                <Text align="start" type="basic" >
                    <Text color={colors.main}>MANY PAY </Text>
                    это совокупность нашего огромного FINTECH опыта и собственного отдела разработки
                </Text> 
                <Text align="start" type="basic"    >
                    Если требуются кастомные решения платежной системы, под индивидуальные особенности вашего направления, мы можем реализовать их совершенно бесплатно в кратчайшие сроки
                </Text>
                <ButtonContainer>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <GreenButton>
                            Получить консультацию 
                        </GreenButton> 
                        <Text type="subtext">Оставьте заявку на консультацию</Text>
                    </div>                    
                </ButtonContainer>
            </div>
            <Icon src="/icons/individual_solutions.svg"/>
        </MainContainer>
    </Container>
}

export default IndividualSolutions;