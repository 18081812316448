export const inputs = [
    {
        label: "Логин",
        value: "username",
        placeholder: "Логин"
    },
    {
        label: "Почта",
        value: "email",
        placeholder: "example@mail.ru"
    },
    {
        label: "Пароль",
        value: "password",
        placeholder: "Пароль",
        passwordType: true
    }
]