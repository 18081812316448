import styled from "styled-components";
import { colors, padding, radius } from "../lib/styles";


const Container = styled.button`
    border-radius: ${radius.main};
    color: black;
    background-color: ${colors.main};
    padding: ${padding.main};
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    border: none;
    font-size: 1rem;
`


const GreenButton = ({children, ...rest}) => {
    return <Container {...rest}>
        {children}
    </Container>
}

export default GreenButton;