import React, { Suspense, lazy } from 'react';

const SupportOrders = lazy(() => import('@src/widgets/orders'));
const SupportAssets = lazy(() => import('@src/pages/support/Assets'));

const supportRoutes = [
    { path: '/orders', element: <SupportOrders /> },
    { path: '/assets', element: <SupportAssets /> }
];

export default supportRoutes;
