import React from "react";
import styled from "styled-components";
import { fadeIn } from '../../assets/animations';


export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background);
  animation: ${fadeIn} 1s ease-in-out;
  width: 100%;
  border-bottom: 1px solid rgba(229, 231, 235, 1);
  @media (max-width: 1200px) {
    margin-left: ${({ isSidebarVisible }) => (isSidebarVisible ? '30%' : 'none')};
    width: 100%;
  }
`;

export const ExitButton = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 20px 32px;
  cursor: pointer;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const CalendarContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 4px 12px;
  background-color: var(--primary);
  border: solid 1px var(--border)
`;

export const StyledPrice = styled.p`
    display: flex;
    margin-left: 5px;
    font-size: 14px;
    margin: 0;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const Username = styled.span`
  margin-left: 10px;
  font-size: 14px;
`;

export const UserButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  margin: 20px 32px;
`;