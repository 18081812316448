import styled from "styled-components";
import { colors, padding, radius } from "../lib/styles";
import GreenButton from "./GreenButton";
import Text from "./Text";
import FlyingComponent from "./FlyingComponent";

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    line-height: 40px;
    position: relative;
`

const GrayRectangle = styled.div`
    height: 200px;
    width: 100%;
    border-radius: 8px;
    background: #D9D9D933;
`

const Benefit = styled.div`
    border-radius: ${radius.full};
    border: solid 2px ${colors.main};
    color: white;
    padding: ${padding.small};
    white-space: nowrap;
    font-size: 15px; 
`

const BenefitsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    width: 70%;
`

const benefits = [
    "Оперативная интеграция",
    "Отсутствие чарджбеков",
    "Большой поток платежей",
    "Отличная отзывчивость",
    "Минимальные задержки"
]

const Icon = styled.img`
    z-index: 1;
    height: 800px;
    position: absolute;
    object-fit: cover;
    left: -400px;

    @media(max-width: 800px) {
        display: none;
        position: static;
        left: 0;
    }
`


const Introduction = () => {
    return <Container>
        <Text type="title">Платежная система для <Text color={colors.main}>HIGH-RISK проектов</Text></Text>
        <Text type="subtitle">В любых вертикалях с широкой географией приема платежей</Text>
        <GrayRectangle />
        <BenefitsContainer>
            {benefits.map((benefit, index) => <FlyingComponent fromLeft={index % 2 === 0}>
                <Benefit>{benefit}</Benefit>
            </FlyingComponent>)}
        </BenefitsContainer>
        <GreenButton>
            Оставить заявку
        </GreenButton>
        <Icon src="/icons/money.svg"/>
    </Container>
}

export default Introduction;