import React from 'react';
import { Input } from 'antd';
import 'antd/dist/reset.css'; // Ант Дизайн стили
import styled from 'styled-components';
import Text from "@shared/ui/Text";
import { sizes } from '../../app/constants';

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const Label = styled.label`
  font-size: 16px;
  color: rgb(128,128,128);
`;

const StyledInput = styled(Input)`
    &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px white inset !important;
        -webkit-box-shadow: 0 0 0px 1000px white inset !important;
        -webkit-text-fill-color: #000 !important;
    }

    letter-spacing: 1px;
    padding: 6px;
    margin: 0;
`;

const StyledPassword = styled(Input.Password)`
    &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px white inset !important;
        -webkit-box-shadow: 0 0 0px 1000px white inset !important;
        -webkit-text-fill-color: #000 !important;
    }

    letter-spacing: 1px;
    padding: 8px;
    margin: 0;
`;

const SimpleInput = ({ label, passwordType, ...props }) => {
  // Используем StyledPassword для паролей и StyledInput для других инпутов
  const Component = passwordType ? StyledPassword : StyledInput;

  return (
    <FormGroup>
      <Label htmlFor={props.id}><Text>{label}</Text></Label>
      {/* Рендерим нужный компонент */}
      <Component style={{ fontSize: sizes.default }} {...props} />
    </FormGroup>
  );
};

export default SimpleInput;
