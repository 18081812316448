// src/routes/adminRoutes.js
import React, { Suspense, lazy } from 'react';

const AdminAssets = lazy(() => import('@admin/Assets'));
const AdminDeposit = lazy(() => import('@admin/Deposit'));
const AdminWithdrawl = lazy(() => import('@admin/Withdrawl'));
const AdminOrders = lazy(() => import('@src/widgets/orders'));
const AdminShops = lazy(() => import('@admin/Shops'));
const AdminShopAssosiations = lazy(() => import('@admin/ShopAssosiations'));
const AdminShopTraderAssosiations = lazy(() => import('@admin/ShopTraderAssosiations'));
const AdminTraders = lazy(() => import('@admin/Traders'));
const AdminStatistics = lazy(() => import('@admin/Statistics'));
const AdminHubs = lazy(() => import('@admin/Hubs'));
const AdminArchive = lazy(() => import('@admin/Archive'));
const AdminSettings = lazy(() => import('@admin/Settings'));
const AdminHelp = lazy(() => import('@admin/Help'));
const AdminAgentAssosiations = lazy(() => import('@admin/AgentAssosiations'));
const AdminAgents = lazy(() => import('@admin/Agents'));
const AdminInvite = lazy(() => import('@admin/InvitePeoples'));

const adminRoutes = [
    { path: '/assets', element: <AdminAssets /> },
    { path: '/deposit', element: <AdminDeposit /> },
    { path: '/withdrawl', element: <AdminWithdrawl /> },
    { path: '/orders', element: <AdminOrders /> },
    { path: '/shops', element: <AdminShops /> },
    { path: '/shops/:shop_id', element: <AdminShopAssosiations /> },
    { path: '/shops/:shop_id/traders/:trader_id', element: <AdminShopTraderAssosiations /> },
    { path: '/test', element: <AdminShopTraderAssosiations /> },
    { path: '/agents', element: <AdminAgents /> },
    { path: '/agents/:agent_id', element: <AdminAgentAssosiations /> },
    { path: '/traders', element: <AdminTraders /> },
    { path: '/statistics', element: <AdminStatistics /> },
    { path: '/traders/:trader_id/hubs', element: <AdminHubs /> },
    { path: '/archive', element: <AdminArchive /> },
    { path: '/settings', element: <AdminSettings /> },
    { path: '/help', element: <AdminHelp /> },
    { path: '/invite', element: <AdminInvite />}
];

export default adminRoutes;
