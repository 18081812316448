import React from "react";
import { aligns, sizes, spacing, weights } from "../../app/constants";


const Text = ({children, color, space = "default", weight = "default", size = "default", type = "basic"}) => {
    return <span style={{
        fontWeight: weights[weight], 
        fontSize: sizes[size],
        fontFamily: "Manrope",
        letterSpacing: spacing[space],
        color, 
        textAlign: aligns[type] || "center", 
        lineHeight: "1.5"
    }}>
        {children}
    </span>
}

export default Text;


