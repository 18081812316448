
export const fonts = {
    basic: "15px",
    title: "60px",
    subtitle: "40px"
}

export const colors = {
    main: "#6BF58C"
}

export const radius = {
    main: "50px",
    full: "50px"
}

export const padding = {
    main: "10px 20px 10px 20px",
    small: "2px 20px 2px 20px",
}