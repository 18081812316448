import React, { useState, useEffect, Suspense, lazy } from 'react';
import Loading from '@shared/ui/Loading';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import GlobalStyle from '@src/assets/styles/globalStyles';
import '@src/assets/styles/fonts.css';
import '@src/assets/styles/App.css';
import { fadeIn } from '@src/assets/animations';
import { AuthProvider } from '@shared/AuthContext';
import LandingPage from '../pages/LandingPage';
import InvitationPage from '../pages/InvitationPage';
import ProtectedRoute from '../widgets/ProtectedRoute';
import Sidebar from '@src/widgets/Sidebar';
import Header from '@src/widgets/Header';
import AppRoutes from '@src/app/routes/appRoutes';
import { RedocStandalone } from 'redoc';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModalProvider } from '@shared/ModalContext';
import LoginPage from "../pages/LoginPage";
import ApiProvider from '../shared/api/ApiProvider';


const AppContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  font-family: Manrope;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: ${({ isSidebarVisible }) => (isSidebarVisible ? 'calc(100vw / 7)' : '0')};
  flex: 7;
  @media (max-width: 1200px) {
    margin-left: 0;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  background-color: var(--background);
  margin: 20px 32px;
  color: var(--text);
  animation: ${fadeIn} 1s ease-in-out;
  transition: 1s width;
  
`;

const MainContentWrapper = ({ isSidebarVisible, children, ...rest }) => ( // Враппер чисто для того, чтобы не было ошибки в логах, когда передаем пропс div
  <MainContent isSidebarVisible={isSidebarVisible} {...rest}>
    {children}
  </MainContent>
);

function App() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  const handleClickMainContent = () => {
    if (window.innerWidth <= 1200 && isSidebarVisible) {
      setIsSidebarVisible(false);
    }
  };


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1200) {
        setIsSidebarVisible(false); // Скрыть сайдбар на узких экранах
      } else {
        setIsSidebarVisible(true); // Показать сайдбар на широких экранах
      }
    };

    window.addEventListener('resize', handleResize);

    // Очистка эффекта
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <AuthProvider>
      <ModalProvider>
        <ToastContainer />
        <GlobalStyle />
        <Router>
          <Routes>
            <Route path="/public/api/v1" element={<ApiDocs />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/invite/:invitationId" element={<InvitationPage />} />
            <Route path="/" element={<Navigate to="/login" replace />} />
            {/* Защищенные маршруты */}
              <Route
                path="/*" // Здесь идет защита всех маршрутов
                element={
                  <ProtectedRoute allowedRoles={['admin', 'merchant', 'trader', 'support']}>
                    <AppContainer>
                      <Sidebar isVisible={isSidebarVisible} closeSidebar={() => {setIsSidebarVisible(false)}} />
                      <MainContent
                        toggleSidebar={toggleSidebar}
                        isSidebarVisible={isSidebarVisible}
                        onClick={handleClickMainContent}
                      >
                        <Header toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible} />
                        <ContentContainer>
                          <Suspense fallback={<Loading />}>
                            {/* Здесь будут маршруты */}
                            <ApiProvider />
                            <AppRoutes />
                          </Suspense>
                        </ContentContainer>
                      </MainContent>
                    </AppContainer>
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Router>
      </ModalProvider>
    </AuthProvider>
  );
}




const ApiDocs = () => {
  return (
    <div>
      <RedocStandalone  specUrl={`/combined_openapi.json`} />
    </div>
  );
};





export default App;
