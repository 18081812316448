import React, { useState } from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
    padding: 12px;
    border-radius: 10px;
    border: none;
    width: 100%;
`


const PhoneNumberInput = () => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const formatPhoneNumber = (value) => {
    // Убираем все символы, кроме цифр
    const cleaned = ('' + value).replace(/\D/g, '');

    // Разбиваем на части
    const match = cleaned.match(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/);

    if (match) {
      const intlCode = (match[1] ? '+7 ' : ''); // Предполагаем, что код страны всегда будет +7
      const part1 = match[2] ? `(${match[2]})` : '';
      const part2 = match[3] ? ` ${match[3]}` : '';
      const part3 = match[4] ? `-${match[4]}` : '';
      const part4 = match[5] ? `-${match[5]}` : '';

      return `${intlCode}${part1}${part2}${part3}${part4}`;
    }

    return value;
  };

  const handleChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setPhoneNumber(formattedPhoneNumber);
  };

  return (
    <div>
      <StyledInput
        type="text"
        value={phoneNumber}
        onChange={handleChange}
        placeholder="+7 (___) ___-__-__"
        maxLength="18" // Максимум символов для формата +7 (XXX) XXX-XX-XX
      />
    </div>
  );
};

export default PhoneNumberInput;
