import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";


export const api = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    withCredentials: true // Включаем куки для междоменных запросов
});

export default function() {
    const navigate = useNavigate();

    useEffect(() => {
        api.interceptors.response.use(
            (response) => response,
            (error) => {
                if(error?.status === 401) {
                    navigate('/login'); // Редирект на страницу логина
                }
            }
        );
    }, []);    
}