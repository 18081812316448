import React from "react";
import { colors } from "../lib/styles";
import styled from "styled-components";
import Text from "./Text";
import FlyingComponent from "./FlyingComponent";


const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    line-height: 40px;
    position: relative;
`

const GlassCard = styled.div`
  background: rgba(255, 255, 255, 0.1); /* Немного прозрачный белый цвет */
//   backdrop-filter: blur(10px); /* Эффект размытости, создающий стеклянный вид */
  border-radius: 15px; /* Закругленные углы */
  flex-direction: column;
  display: flex;
  height: 100%;
  align-items-center;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Тень для глубины */
  padding: 20px; /* Внутренние отступы для контента */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Полупрозрачная рамка */
  position: relative; /* Для позиционирования бликов */
  text-align: center;
`;

const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;

    @media (max-width: 900px) {
        grid-template-columns: 1fr 1fr; /* Две колонки для ширины экрана 800px и меньше */
    }

    @media (max-width: 500px) {
        grid-template-columns: 1fr; /* Одна колонка для ширины экрана 500px и меньше */
    }
`

const elements = [
    { title: "Платежи", highlighted: "CRYPTO", description: "Прием платежей за BitCoin, Ethereum и прочие криптовалюты" },
    { title: "Платежи", highlighted: "PHARM", description: "Фармацевтика. Фармацевтические продукты" },
    { title: "Платежи", highlighted: "ADULT", description: "Сайты для взрослых, эдалт (xxx, adult payments), порно-сайты и видеочаты" },
    { title: "Платежи", highlighted: "SOFTWARE", description: "Программное обеспечение, SMS-услуги, антивирусы (antivirus payments)" },
    { title: "Платежи", highlighted: "CASINO", description: "Игорный бизнес, казино, лотереи, аукционы (gambling and casino payments)" },
    { title: "Платежи", highlighted: "DATING", description: "Агентства знакомств, эскорт услуги (dating services payments)" },
    { title: "Платежи", highlighted: "MIDDLE-RISK", description: "Турагентства, ломбарды, аренда, бронирование отелей и т.п." },
    { title: "Платежи", highlighted: "E-COMMERCE", description: "Коммерческая торговля физическими и виртуальными товарами" }
];


const SecondBlock = () => {
    return <Container>
        <Text type="subtitle">
            <Text color={colors.main}>Мы помогаем расти </Text> 
                бизнес-проектам в любых вертикалях
        </Text>
        <CardsContainer>
            {elements.map(({title, highlighted, description}, index) => <>
                <FlyingComponent fromLeft={[0, 1, 4, 5].includes(index)}>
                    <GlassCard>
                        <Text type="pretitle">{title}</Text>
                        <Text color={colors.main} type="pretitle">{highlighted}</Text>
                        <Text type="card">{description}</Text>
                    </GlassCard>
                </FlyingComponent>
            </>)}
        </CardsContainer>
    </Container>
}

export default SecondBlock;