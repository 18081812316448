// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../shared/AuthContext';
import Loading from '@shared/ui/Loading';

const ProtectedRoute = ({ children, allowedRoles, redirectPath }) => {
  const { user, loading } = useAuth();
  if (loading) {
    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Loading /></div>;
    
  }
  if (!user) {
    // Если пользователь не авторизован, перенаправляем на страницу входа
    return <Navigate to="/login" />;
  }
  
  return children;
};

export default ProtectedRoute;

// check_something
