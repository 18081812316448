import React, { createContext, useContext, useState, useEffect } from 'react';
import makeRequest from './api/makeRequest';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true // Включаем куки для междоменных запросов
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    fetch(process.env.REACT_APP_API_HOST + '/api/v1/auth/users/me', {
      method: 'GET',
      credentials: "include",
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        return response.json();
      })
      .then(data => {
        setUser({ id: data.id, username: data.username, role: data.role });
      })
      .catch(error => {
        console.error('Error fetching user data', error);
        setUser(null);
      })
      .finally(() => setLoading(false));
  }, []);


  const login = async (username, password) => {
    try {
      const response = await request();

      if (!response.ok) {
        throw new Error('Invalid credentials');
      }

      const data = await response.json();
      setUser({ id: data.id, username: data.username, role: data.role });
    } catch (error) {
      console.error('Login failed', error);
      throw error;
    } finally {
      setLoading(false);
    }

    async function request() {
      return await fetch(process.env.REACT_APP_API_HOST + '/api/v1/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
        credentials: "include", 
      })
    }
  };

  const logout = async () => {
    await makeRequest('/api/v1/auth/logout') 
    setUser(null);
  };

  const createUser = async (username, password, email, invite_id) => {
    await makeRequest('/api/v1/core/create_user/create_account', {
      body: {
          username,
          password,
          email,
          invite_id,
      }
    })
  }
  
  return (
    <AuthContext.Provider value={{ user, login, logout, loading, createUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};