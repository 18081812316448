import FormPage from "@shared/ui/widgets/FormPage";
import { useAuth } from "@shared/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'


const LoginPage = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  return <FormPage
    title="Вход"
    handleSubmit={handleSubmit}
    inputs={[
      {
        label: "Логин",
        value: "username",
        placeholder: "Логин",
      },
      {
        label: "Пароль",
        value: "password",
        placeholder: "Пароль",
        passwordType: true
      }
    ]}
  />

  async function handleSubmit({username, password}) {
    try {
      await login(username, password);
      navigate('/assets');
    } catch(e) {
      console.log(e);
      toast.error("Ошибка входа");
    }
  };
}

export default LoginPage;