import React from "react"
import styled from "styled-components"


const Flex = styled.div`
    display: flex;
    ${props => props.justify_center ? `justify-content: ${props.justify};` : ""}
    ${props => props.align ? `align-items: ${props.align};` : ""}
    ${props => props.width ? `width: ${props.width};` : ""}
    ${props => props.column ? `flex-direction: column;` : ""}
    ${props => props.gap ? `gap: ${props.gap};` : ""}
`

export default Flex;