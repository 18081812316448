export default {
    default: {
        color: "white",
        border: "solid 1px rgb(40, 40, 43)",
        background: "rgb(40, 40, 43)", 
    },
    inversed: {
        color: "black",
        border: "solid 1.5px black",
        background: "white",
    }
}